<template>
  <!--begin::Contact page-->
  <div>
    <a href="https://goo.gl/maps/EJHyZDgwDoaNpdvz8" target="_blank">
      <v-img src="/media/landing/contact/google.jpg" class="w-100" />
    </a>

    <LandingContactZone />
  </div>
  <!--end::Contact page-->
</template>

<script>
import LandingContactZone from "@/view/pages/landing/ContactZone.vue";

export default {
  name: "LandingContact",

  components: {
    LandingContactZone
  },

  mounted() {
    this.$nextTick(() => {
      document.title =
        this.$t("HEADER.TITLE") + " " + this.$t("LANDING.CONTACT.TITLE");
    });
  }
};
</script>
